<template>
  <SpecificCompanyLogin ></SpecificCompanyLogin>
</template>
<script>
import SpecificCompanyLogin from './specificCompanyLogin'
export default {
  components: {
    SpecificCompanyLogin
  },
  data () {
    return {
      showGeneralLogin: false
    }
  },
  created () {
    let subdomain = location.hostname.split('.').shift()
    if (process.env.NODE_ENV === 'development') {
      subdomain = 'itp'
    }
    if (subdomain === 'app') {
      this.showGeneralLogin = true
    }
  }
}
</script>
