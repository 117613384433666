<template>
  <div id="inspire">
    <div>
      <div fluid class="backgroundWindow flex justify-center items-center p-0 h-screen px-4">
        <div class="bg-white card rounded-xl card_design w-full px-6 sm:px-12 py-4 sm:m-0">
          <div class="flex-wrap">
            <div
              class="text-left"
            >
              <div class="mb-3 p-0 ">
                
                <div v-if="!forgotPin">
                  <div class="text-center flex items-center justify-center">
                    <img src="@/assets/images/ZizbeyJobsLogo.svg" />
                    <div class="heading-2 text-primary font-semibold pl-1">ZizbeyJobs</div>
                  </div>
                  <div class=" font-semibold heading-3 text-center text-text1 py-4">
                  Welcome back
                  </div>
                  <div class="mb-4">
                     <TextField
                      :inputId="`useremailid`"
                      :inputext="userEmail"
                      :placholderText="`Username`"
                      :lableText="'Username'"
                      :fieldError="emailErr !== ''"
                      :autoFocus="true"
                      :inputType="'text'"
                      @keyPressAction="emailErr = ''"
                      @enterkeyPressAction="loginActionCall"
                      @blurAction="userEmail.trim() === '' ? emailErr = 'Username is required' : emailErr = '' "
                      @inputChangeAction="(data) => userEmail = data"  />
                      
                      <p class="text-error"> {{ emailErr }}</p>
                  </div>
                  <div class="mb-4">
                     <TextField
                      :inputId="`userpassid`"
                      :inputext="userPass"
                      :placholderText="`Pin`"
                      :lableText="'Pin'"
                      :fieldError="passErr !== ''"
                      :autoFocus="false"
                      :inputType="'password'"
                      @enterkeyPressAction="loginActionCall"
                      @keyPressAction="passErr = ''"
                      @blurAction="userPass.trim() === '' ? passErr = 'Password is required' : passErr = '' "
                      @inputChangeAction="(data) => userPass = data"  />
                      <p class="text-error"> {{ passErr }}</p>
                  </div>
                  <p @click="forgotPin = true" class="text-primary cursor-pointer heading-6 text-right py-2 pt-0">Forgot Pin</p>
                  <span class="heading-6 text-gray4  py-2">By signing in you agree to the <span class="text-primary cursor-pointer" @click="routingtoLink('terms')">Terms of Service</span> and <span class="text-primary cursor-pointer" @click="routingtoLink('privacy')">Privacy Policy</span></span>
                  <div class="py-2">
                    <Button  class="flex text-center justify-center h-10 w-full" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Log In'" @buttonAction="loginActionCall()"/>
                  </div>
                  <div class=" text-primary text-center mt-2">
                    <span class="heading-6 text-gray3 ">New User? </span>
                    <span @click="goRegisterAsOwner" class=" font-semibold cursor-pointer">Signup Now</span>
                  </div>
                </div>
                <div v-if="forgotPin && !verifyOtp">
                  <div class=" font-semibold heading-2 text-center text-text1 py-4">
                  Forgot Pin
                  </div>
                  <div class="mb-4">
                      <TextField
                        :inputId="`useremailid`"
                        :inputext="forgotEmail"
                        :placholderText="`Email Address`"
                        :lableText="'Email Address'"
                        :fieldError="forgotEmailErr !== ''"
                        :autoFocus="true"
                        :inputType="'text'"
                        @keyPressAction="forgotEmailErr = ''"
                        @enterkeyPressAction="sendOtp"
                        @blurAction="emailHandler(forgotEmail, 'forgot')"
                        @inputChangeAction="(data) => forgotEmail = data"  />
                        
                        <p class="text-error"> {{ forgotEmailErr }}</p>
                    </div>
                    <div class="py-2">
                      <Button  class=" h-10 w-full" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Next'" @buttonAction="sendOtp()"/>
                    </div>
                    <div class=" text-primary text-center mt-2">
                      <span @click="forgotPin = false" class=" cursor-pointer font-bold">Go to Sign in</span>
                    </div>
                </div>
                <div v-if="verifyOtp && !changePin">
                  <p class="">Please enter OTP sent to <span class="font-semibold">{{forgotEmail}}</span></p>
                    <div class=" my-4 flex justify-center">
                        <div class="w-56">
                        <TextField
                        :isOtp="true"
                        :inputId="`otpinputvalueid`"
                        :inputext="otpValue"
                        :placholderText="``"
                        :lableText="''"
                        :autoFocus="true"
                        :inputType="'text'"
                        @inputChangeAction="(data) => otpValue = data"  />
                        </div>
                    </div>
                    <div class="flex justify-end mb-4">
                        <div v-if="countDown > 0" class="">Resend OTP  <span >in {{countDown}} Sec</span></div>
                        <div v-else @click="reSendOtp" class=" cursor-pointer text-primary">Resend OTP</div>
                    </div>
                    <div class="py-2">
                      <Button  :disabled="otpValue ===''" class=" h-10 w-full" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Next'" @buttonAction="verifysOtp()"/>
                    </div>
                    <div class=" text-primary text-center mt-2">
                      <span @click="forgotPin = false" class=" cursor-pointer font-bold">Go to Sign in</span>
                    </div>
                </div>
                <div v-if="forgotPin && changePin">
                    <div class="my-4 text-text1 flex justify-center">
                        <div class=" text-8xl relative">
                            <i class="fa-solid fa-mobile-screen-button"></i>
                            <span class="text-4xl absolute right-5 text-primary top-5"><i class="fa-solid fa-lock"></i></span>
                        </div>
                    </div>
                    <p class="mb-4 font-semibold heading-3 text-center">Change your Application Pin</p>
                    <div class=" mb-4">
                        <div class="">
                        <TextField 
                        :textMaxlength="4"
                        :fieldError="setPinValueErr !== ''"
                        @keyPressAction="setPinValueErr = ''"
                        :inputId="`pinsetinpuvalur`"
                        :inputext="setPinValue"
                        :placholderText="`Pin`"
                        :lableText="'Set 4 digit Pin'"
                        :autoFocus="true"
                        :inputType="'text'"
                        @inputChangeAction="(data) => setPinValue = data"  />
                        <p class="text-error"> {{ setPinValueErr }}</p>
                        </div>
                    </div>
                    <div class=" mb-4">
                        <div class="">
                        <TextField
                        :textMaxlength="4"
                        :fieldError="confirmPinErr !== ''"
                        @keyPressAction="confirmPinErr = ''"
                        :inputId="`confirminputid`"
                        :inputext="confirmPin"
                        :placholderText="`Pin`"
                        :lableText="'Confirm Pin'"
                        :autoFocus="false"
                        :inputType="'text'"
                        @inputChangeAction="(data) => confirmPin = data"  />
                        <p class="text-error"> {{ confirmPinErr }}</p>
                        </div>
                    </div>
                    <div class="py-2">
                    <Button :disabled="setPinDisabled"  class=" h-10 w-full" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Next'" @buttonAction="subminAppPin()"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loader
      v-if="$store.getters.showLoader"
      v-bind:data="$store.getters.loaderMessage"
    ></loader>
    <snakBar
        v-if="$store.getters.showAlert"
        v-bind:status="$store.getters.showAlert"
        v-bind:message="$store.getters.alertMessage"
        v-bind:color="$store.getters.alertColor"
      >
      </snakBar>
  </div>
</template>
<script>
import Loader from '@/View/components/Loader.vue'
import snakBar from '@/View/components/SnakBar.vue'
import TextField from '@/View/components/textfield.vue'
import Button from '@/View/components/globalButton.vue'
import MyJobApp from '@/api/MyJobApp.js'
import {getEncryptedPassword} from '@/utils/encrytPassword'
import {validateEmail} from '@/utils/validation.js'
import {setCookiesWithExpire} from '@/utils/cookies.js'
export default {
  components: {
    Loader,
    snakBar,
    TextField,
    Button,
  },
  data () {
    return {
      userEmail: '',
      userPass: '',
      emailErr: '',
      passErr: '',
      emailValid: false,
      forgotEmail: '',
      forgotEmailErr: '',
      forgotPin: false,
      verifyOtp: false,
      changePin: false,
      regToken: '',
      otpValue: '',
      countDown: 30,
      confirmPin: '',
      setPinValue: '',
      confirmPinErr: '',
      setPinValueErr: '',
      setPinDisabled: true,
    }
  },
  beforeMount () {},
  created () {},
  mounted () {},
  watch: {
    forgotPin: {
        handler () {
            if (!this.forgotPin) {
              this.verifyOtp = false
              this.changePin = false
              this.setPinDisabled = false
            }            
        }
    },
    confirmPin: {
        handler () {
            if (this.confirmPin !== '' && this.confirmPin === this.setPinValue) {
                this.setPinDisabled = false
                this.confirmPinErr = ''
              } else {
                this.setPinDisabled = true
                if (this.confirmPin !== '' && this.setPinValue !== '') {
                  this.confirmPinErr = 'PIN does not match'
                }
            }
            
        }
    },
    setPinValue: {
        handler () {
            if (this.setPinValue !== '' && this.confirmPin === this.setPinValue) {
                this.setPinDisabled = false
                this.confirmPinErr = ''
            } else {
                this.setPinDisabled = true
                if (this.confirmPin !== '' && this.setPinValue !== '') {
                  this.confirmPinErr = 'PIN does not match'
                }
            }
        }
    },
  },
  methods: {
    subminAppPin () {
        MyJobApp.updateLoginPin(
            this.regToken,
            this.setPinValue,
          response => {
            this.forgotPin = false
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        ) 
    },
    reSendOtp () {
      this.countDown = 30
      this.sendOtp()
    },
    countDownTimer () {
        if (this.countDown > 0) {
            setTimeout(() => {
                this.countDown -= 1
                this.countDownTimer()
            }, 1000)
        }
    },
    verifysOtp () {
      this.$store.dispatch("SetLoader", { status: true, message: "Loading...", });
        MyJobApp.forgotPinVerifyOtp(
            this.regToken,
            this.otpValue,
          () => {
            this.changePin = true
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: 'OTP verified successfully', color: 'success'})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        ) 
    },
    sendOtp () {
      if (this.forgotEmailErr === '') {
        this.$store.dispatch("SetLoader", { status: true, message: "Loading...", });
        MyJobApp.forgotPinOtpSend(
          this.forgotEmail,
          (response) => {
            this.regToken = response.Data
            this.verifyOtp = true
            this.countDownTimer()
            this.$store.dispatch('SetAlert', {showAlert: true, message: 'OTP send successfully', color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        ) 
      }
    },
    goRegisterAsOwner () {
      this.$router.push({name: 'registerAsOwner'})
    },
    routingtoLink (value) {
      let url = ''
      if (value === 'terms') {
        url = 'https://www.zizbeyjobs.com/terms'
      } else {
        url = 'https://www.zizbeyjobs.com/privacy-policy'
      }
      window.open(url, '_blank')
    },
    loginActionCall () {
      let isvalid = true

      if (this.userEmail.trim() === "" && this.userPass.trim() === "") {
        this.emailErr = "Username is required"
        this.passErr = "Password is required"
        isvalid = false
      }
      if (this.userPass.trim() === "") {
        this.passErr = "Password is required"
        isvalid = false
      }
      if (this.userEmail.trim() === "") {
        this.emailErr = "Username is required"
        isvalid = false
      }
      // if (!this.emailValid) {
      //   isvalid = false
      // }

     let encPassword = getEncryptedPassword(this.userPass)
// 'ktOfACBOOjS6ZObPHuCjCrvA1uo=',

      if (isvalid) {
        MyJobApp.login(
          this.userEmail,
          encPassword,
          response => {
            let latLong = {
                latitude: response.Data.organizationAddress.latitude,
                longitude: response.Data.organizationAddress.longitude
              }

            let LoginUserlatLong = null
              // Check if geolocation is supported by the browser
              if ("geolocation" in navigator) {
                  // Requesting current position
                  navigator.geolocation.getCurrentPosition(function(position) {
                      // Success callback
                      const latitude = position.coords.latitude;
                      const longitude = position.coords.longitude;
                      LoginUserlatLong = {
                        latitude: latitude,
                        longitude: longitude
                      }
                      localStorage.setItem('LoginUserlatLong', JSON.stringify(LoginUserlatLong))
                      // You can do something with the latitude and longitude here
                  }, function(error) {
                      // Error callback
                      console.error(`Error getting geolocation: ${error.message}`);
                  });
              } else {
                  console.log("Geolocation is not supported by this browser.");
              }


            
            localStorage.setItem('orgInfo', JSON.stringify(latLong))
            localStorage.setItem('jobUserInfo', JSON.stringify(response.Data.userInfo))
            setCookiesWithExpire('jobAuthToken', response.Data.userInfo.authToken, 30)
            this.$store.dispatch('SetAlert', {showAlert: true, message: 'Logged In successfully', color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$router.push({name: 'dashboard1'})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        ) 
      }
    },
    emailHandler (emailId, from) {
      if (from === '') {
        this.emailErr = this.emailValidation(emailId)
        if (this.emailErr === 'Email address is invalid') {
          this.emailValid = false
        } else {
          this.emailValid = true
        }
      } else if (from === 'forgot') {
        this.forgotEmailErr = this.emailValidation(emailId)
      }
    },
     emailValidation (emailId) {
      if (emailId === '') {
        return ''
      } else if (validateEmail(emailId) === false) {
        return 'Email address is invalid'
      } else {
        return ''
      }
    },
  },
  beforeDestroy () {},
  computed: {
  }
}
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Saira+Stencil+One&display=swap");
.backgroundWindow {
  background: url('https://workapp-public-download.s3.ap-south-1.amazonaws.com/assets/signup-in-background.jpg');
  background-size: cover;
  background-position: center
}
.card_design {
  max-width: 500px;
}

</style>
